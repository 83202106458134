import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { take, tap } from 'rxjs';
import AuthService from '@/services/auth.service';
import PermissionService from '@/services/permission.service';
import ToastsService from '@/services/toasts.service';
import DirectManagersService from '@/services/directManagers.service';
// Routes templates
const LeftSidebarTemplate = () => import('@/templates/routes/LeftSidebar.template.vue');
// Routes
const Forms = () => import('@/views/forms/Forms.vue');
const FromEdit = () => import('@/views/forms/FormEdit.vue');
const FormCreate = () => import('@/views/forms/FormCreate.vue');
const AnonymousFormShow = () => import('@/views/forms/AnonymousFormShow.vue');
const PersonalizedFormShow = () => import('@/views/forms/PersonalizedFormShow.vue');
const IncomingFormShow = () => import('@/views/forms/IncomingFormShow.vue');
const FormAnswers = () => import('@/views/forms/FormAnswers.vue');
const Login = () => import('@/views/Login.vue');
const Dashboard = () => import('@/views/Dashboard.vue');
const Categories = () => import('@/views/Categories.vue');
const CategoryDetails = () => import('@/views/CategoryDetails.vue');
const Questions = () => import('@/views/Questions.vue');
const Users = () => import('@/views/Users.vue');
const UserDetails = () => import('@/views/UserDetails.vue');
const Groups = () => import('@/views/Groups.vue');
const GroupDetails = () => import('@/views/GroupDetails.vue');
const RecruitingKpi = () => import('@/views/RecruitingKpi.vue');
const SkdSettings = () => import('@/views/SkdSettings.vue');
const EmployeesFilesReport = () => import('@/views/bamboo_hr/EmployeesFilesReport.vue');
const UserProfile = () => import('@/views/UserProfile.vue');
const MeetingRooms = () => import('@/views/meetings/MeetingRooms.vue');
const Meetings = () => import('@/views/meetings/Meetings.vue');
const SummaryDirectories = () => import('@/views/recruiting/SummaryDirectories.vue');
const CatsUsersCatalog = () => import('@/views/recruiting/CatsUsersCatalog.vue');
const GiftManagement = () => import('@/views/GiftManagement.vue');
const GiftCreatePage = () => import('@/views/gifts/GiftCreatePage.vue');
const SettingsCreatePage = () => import('@/views/gifts/Settings/SettingsCreatePage.vue');
const GiftDetails = () => import('@/views/gifts/GiftDetails.vue');
const GiftOrder = () => import('@/views/gifts/GiftOrder.vue');
const WorkingHoursHistory = () => import('@/views/user/WorkingHoursHistory.vue');

// TODO 404 routes for groups of routes

const recursiveSearchCanEnterRoute = (route: RouteRecordRaw): RouteRecordRaw | null => {
    const { meta, name, children, path = '' } = route;
    const { permissions = [] } = meta || {};
    const hasPermissions = !permissions.length || PermissionService.userCan(permissions);
    const needParams = new RegExp(/:/gm).test(path);
    if (!needParams) {
        if (name && hasPermissions) {
            return route
        } else if (children && children.length && hasPermissions) {
            for (const child of children) {
                const childRoute = recursiveSearchCanEnterRoute(child);
                if (childRoute) {
                    return childRoute;
                }
            }
        }
    }
    return null;
}

const routes: RouteRecordRaw[] = [
    {
        path: '/login',
        component: Login,
        name: 'login',
        beforeEnter: (to, from, next) => {
            AuthService.user$.pipe(
                take(1)
            ).subscribe((user) => {
                if (user && AuthService.authToken) {
                    const authorizedRoutes = routes.find(({ path }) => path === '/');
                    if (authorizedRoutes) {
                        const hasPermissionsRoute = recursiveSearchCanEnterRoute(authorizedRoutes);
                        if (hasPermissionsRoute) {
                            router.replace({ name: hasPermissionsRoute.name }).then(() => {});
                        }
                    }
                } else {
                    next();
                }
            });
        }
    },
    {
        path: '/',
        component: LeftSidebarTemplate,
        beforeEnter: (to, from, next) => {
            if (to.path === '/') {
                const authorizedRoutes = routes.find(({ path }) => path === '/');
                if (authorizedRoutes) {
                    const hasPermissionsRoute = recursiveSearchCanEnterRoute(authorizedRoutes);
                    if (hasPermissionsRoute) {
                        router.replace({ name: hasPermissionsRoute.name }).then(() => {});
                    }
                }
            } else {
                next();
            }
        },
        children: [
            {
                path: 'ela-bot',
                meta: {
                  permissions: ['Nlp']
                },
                children: [
                    {
                        path: 'dashboard',
                        component: Dashboard,
                        name: 'dashboard'
                    },
                    {
                        path: 'categories',
                        children: [
                            {
                                path: '',
                                component: Categories,
                                name: 'categories'
                            },
                            {
                                path: 'category-details/:categoryId',
                                component: CategoryDetails,
                                name: 'category-details'
                            }
                        ]
                    },
                    {
                        path: 'questions',
                        component: Questions,
                        name: 'questions'
                    }
                ]
            },
            {
                path: 'groups',
                meta: {
                    permissions: ['Groups.show']
                },
                children: [
                    {
                        path: '',
                        component: Groups,
                        name: 'groups'
                    },
                    {
                        path: 'group-details/:groupId',
                        component: GroupDetails,
                        name: 'groupDetails',
                        meta: {
                            permissions: ['Groups.modify']
                        }
                    }
                ]
            },
            {
                path: 'users',
                meta: {
                    permissions: ['Users.show']
                },
                children: [
                    {
                        path: '',
                        component: Users,
                        name: 'users'
                    },
                    {
                        path: 'user-details/:id',
                        component: UserDetails,
                        name: 'userDetails',
                        meta: {
                            permissions: ['Users.modify'],
                            checkHierarchy: true
                        }
                    }
                ]
            },
            {
                path: 'me',
                component: UserProfile,
                name: 'Profile'
            },
            {
                path: 'working-hours-history',
                component: WorkingHoursHistory,
                name: 'WorkingHoursHistory'
            },
            {
                path: 'meetings',
                children: [
                    {
                        path: '',
                        component: Meetings,
                        name: 'meetings',
                        meta: {
                            permissions: ['Developers']
                        }
                    },
                    {
                        path: 'rooms',
                        component: MeetingRooms,
                        name: 'meetingRooms',
                        meta: {
                            permissions: ['MeetingRooms.show']
                        }
                    }
                ]
            },
            {
                path: 'forms',
                meta: {
                    permissions: ['Forms.show']
                },
                children: [
                    {
                        path: '',
                        component: Forms,
                        name: 'forms'
                    },
                    {
                        path: 'create',
                        component: FormCreate,
                        name: 'formCreate',
                        meta: {
                            permissions: ['Forms.modify']
                        }
                    },
                    {
                        path: 'edit/:formId',
                        component: FromEdit,
                        name: 'formEdit',
                        meta: {
                            permissions: ['Forms.modify']
                        }
                    },
                    {
                        path: 'answers/:formId',
                        component: FormAnswers,
                        name: 'formAnswers',
                        meta: {
                            permissions: ['Forms.show']
                        }
                    }
                ]
            },
            {
                path: 'surveys/:uuid',
                component: PersonalizedFormShow,
                name: 'userSurveysShow'
            },
            {
                path: 'incoming',
                component: IncomingFormShow,
                name: 'incomingForm',
                meta: {
                    permissions: ['IncomingForm.show']
                }
            },
            {
                path: 'gift-management',
                children: [
                    {
                        path: '',
                        component: GiftManagement,
                        name: 'GiftManagement',
                        meta: {
                            permissions: ['Products.show']
                        },
                    },
                    {
                        path: 'create-gift',
                        component: GiftCreatePage,
                        name: 'createGift',
                        meta: {
                            permissions: ['Products.modify']
                        }
                    },
                    {
                        path: 'create-gifts-settings',
                        component: SettingsCreatePage,
                        name: 'createSettings',
                        meta: {
                            permissions: ['Products.modify']
                        }
                    }
                ]
            },
            {
                path: 'gift-management/:tab',
                component: GiftManagement,
                name: 'GiftManagementByTabId',
                meta: {
                    permissions: ['Products.show']
                },
            },
            {
                path: 'gifts',
                children: [
                    {
                        path: 'gift-details/:productId',
                        component: GiftDetails,
                        name: 'giftDetails',
                    },
                    {
                        path: 'order',
                        component: GiftOrder,
                        name: 'giftOrder'
                    }
                ]
            },
            {
                path: 'recruiting',
                children: [
                    {
                        path: '',
                        component: RecruitingKpi,
                        name: 'recruitingKpi',
                        meta: {
                            permissions: ['Recruiting.show']
                        }
                    },
                    {
                        path: 'summary-directories',
                        component: SummaryDirectories,
                        name: 'SummaryDirectories',
                        meta: {
                            permissions: ['Recruiting.sumDirectories.show']
                        }
                    },
                    {
                        path: 'cats-users-catalog',
                        component: CatsUsersCatalog,
                        name: 'CatsUsersCatalog',
                        meta: {
                            permissions: ['Recruiting.sumDirectories.show']
                        }
                    }
                ]
            },
            {
                path: 'skd',
                children: [
                    {
                        path: '',
                        component: SkdSettings,
                        name: 'SkdSettings',
                        meta: {
                            permissions: ['Skd.settings.modify']
                        }
                    }
                ]
            },
            {
                path: '/bamboo-hr',
                component: EmployeesFilesReport,
                name: 'BambooHr Tools',
                meta: {
                    permissions: ['BambooHr.showReports']
                }
            }
        ]
    },
    {
        path: '/anonymous/:formUuid',
        component: AnonymousFormShow,
        name: 'anonymous'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const loginRedirectQuery = to?.name && to.fullPath !== 'login' ? { redirectTo: to.fullPath.toString() } : {};
    if (!AuthService.authToken) {
        if (to.name === 'login') {
            next();
        } else if (to.name === 'anonymous') {
            next();
        } else {
            next({
                name: 'login',
                query: loginRedirectQuery
            });
        }
    } else {
        AuthService.getMe().pipe(
            tap((user) => {
                if (!user) {
                    AuthService.resetAuthToken();
                    throw new Error('User is null');
                }
            })
        ).subscribe({
            next: () => {
                next();
            },
            error: () => {
                AuthService.resetAuthToken();
                next({
                    name: 'login',
                    query: loginRedirectQuery
                });
            }
        });
    }
});

router.beforeEach((to, from, next) => {
    const needPermissions = PermissionService.collectPermissionFromRouter(to);
    if (!needPermissions.length) {
        next();
    } else {
        const userCan = PermissionService.userCan(needPermissions);
        if (userCan) {
            next();
        } else if (to.meta.checkHierarchy) {
            const userCanByHierarchy = DirectManagersService.userCan(to);
            if (userCanByHierarchy) {
                next();
            } else { 
                // next(false);
                next({ path: '/me' }); //YH-5309 1.3
                ToastsService.globalToasts.next({
                    severity: 'error',
                    summary: 'You must have permissions OR be a chief for visit this route',
                    life: 5000
                });
            }
        } else {
            if (from.name && from.name !== 'login') {
                next(false);
            } else {
                next({ path: '/' })
            }
            ToastsService.globalToasts.next({
                severity: 'error',
                summary: 'You do not have permissions for this route',
                life: 5000
            });
        }
    }
});

export { router, routes };
